import React, { useEffect, useState } from "react";
import {
  CopyIcon,
  DownloadIcon,
  ThumbsDownIcon,
  ThumbsDownIconFill,
  ThumbsUpIcon,
  ThumbsUpIconFill,
  UpCarpet,
} from "../../icons";
import kfcBot from "../../assets/img/kfcbot.png";
import pizzaBot from "../../assets/img/pizzabot1.png";

import { useSelector } from "react-redux";

import { userName } from "../../utils/utils";
import ScrollToBottom from "react-scroll-to-bottom";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { usePostVoetesMutation } from "../../services/conversationService";
import ReactMarkdown from "react-markdown";
import { FaArrowCircleUp } from "react-icons/fa";

const MySwal = withReactContent(Swal);

const botDimensions = {
  kfc: { width: 32, height: 32 },
  pizzahut: { width: 32, height: 32 },
};

const name = userName();

const MessageList = React.memo(({ data, prompt }) => {
  const userAccess = useSelector((state) => state.userAccess);
  const [postVotes, postVotesInfo] = usePostVoetesMutation();

  // const selectedConversation = useSelector(
  //   (state) => state.selectedConversation
  // );

  // const [getHistory, { data, error, isLoading }] = useLazyGetHistoryQuery();

  const [bot, setBot] = useState(
    userAccess.brand === "kfc" ? kfcBot : pizzaBot
  );

  const [width, setWidth] = useState(32);
  const [height, setHeight] = useState(32);

  useEffect(() => {
    if (bot) {
      const brand = userAccess.brand;

      const { width, height } = botDimensions[brand];
      setWidth(width);
      setHeight(height);
    }
  }, [bot, userAccess.brand]);

  const handleVote = (
    action,
    response_id,
    conversation_id,
    alreadyDone = false
  ) => {
    if (!alreadyDone) {
      MySwal.fire({
        title:
          action === "like"
            ? "What do you like about the response?"
            : "Why you disliked this response?",
        input: "textarea",
        inputAttributes: {
          autocapitalize: "off",
        },
        showCancelButton: true,
        confirmButtonText: "Submit",
        showLoaderOnConfirm: true,
        customClass: {
          confirmButton: "customDangerBtn",
          cancelButton: "customCancelBtn",
        },
        preConfirm: async (details) => {
          const body = {
            response_id: response_id,
            feedback: action === "like",
            feedback_details: details,
            conversation_id,
          };
          console.log(body, "login");
          try {
            await postVotes(body).unwrap();
            return MySwal.fire({
              text: "You feedback submitted successfully",
              icon: "success",
              customClass: {
                confirmButton: "customDangerBtn",
              },
            });
          } catch (error) {
            console.log(error);
            return MySwal.fire({
              text: error && error.data ? error.data.detail : error,
              icon: "error",
              customClass: {
                confirmButton: "customDangerBtn",
              },
            });
          }
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        // if (result.isConfirmed) {
        //   MySwal.fire({
        //     text: "You feedback submitted successfully",
        //     icon: "success",
        //     customClass: {
        //       confirmButton: "customDangerBtn",
        //     },
        //   });
        // }
      });
    }
  };

  // useEffect(() => {
  //   if (postVotesInfo.isSuccess) {
  //   } else if (postVotesInfo.isError) {
  //   }
  // }, [postVotesInfo]);

  const handleCopyClick = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {
      console.error("Unable to copy text to clipboard", err);
    }
  };

  return (
    <ScrollToBottom className="flex relative left flex-col overflow-auto">
      <div className="border-0 mx-6 mr-10  mt-3 justify-center ">
        {!prompt &&
          data.map(
            ({ response_id, message, prompt, feedback, conversation_id }) => (
              <span key={response_id}>
                <div className="tyn-qa-item flex gap-4 w-full">
                  <div className="tyn-qa-avatar align-middle ">
                    <div className="tyn-media tyn-size-md">
                      <div className="relative inline-flex items-center justify-center w-8 h-8 overflow-hidden  rounded-full bg-primary-red object-contain 	">
                        <span className="font-medium text-primary-white">
                          {name}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="self-center bg-primary-black text-primary-white font-normal text-sm w-full rounded-lg flex justify-between p-[10px]">
                    <p className="text-base font-medium">{prompt}</p>
                    <span className="float-right self-center ">
                      <UpCarpet width="28" height="28" />
                    </span>
                  </div>
                </div>
                <div className="tyn-qa-item flex gap-4 w-full mt-3">
                  <div className="tyn-qa-avatar">
                    <div className="tyn-qa-avatar-wrap">
                      <div className="tyn-media tyn-size-md">
                        <img
                          src={bot}
                          alt="Avatar"
                          width={width}
                          height={height}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="ans-block self-center text-primary-black  font-normal p-2 text-sm w-full group relative ">
                    <div className="text-base  gap-2">
                      <ReactMarkdown>{message}</ReactMarkdown>
                    </div>
                    <div className="d-flex flex-column action-icons justify-center  transition-opacity duration-300 flex flex-row sm:hidden gap-2  text-center  group-hover:flex">
                      <span className="mr-0.5">
                        <button
                          className="btn btn-icon btn-md btn-pill btn-transparent"
                          onClick={() =>
                            handleVote(
                              "like",
                              response_id,
                              conversation_id,
                              feedback
                            )
                          }
                        >
                          {feedback ? (
                            <ThumbsUpIconFill
                              color={"#e4022b"}
                              fill={"#e4022b"}
                            />
                          ) : (
                            <ThumbsUpIcon />
                          )}
                        </button>
                      </span>
                      <span className="mr-0.5">
                        <button
                          className="btn btn-icon btn-md btn-pill btn-transparent"
                          onClick={() =>
                            handleVote(
                              "dislike",
                              response_id,
                              conversation_id,
                              feedback === false
                            )
                          }
                        >
                          {feedback !== null &&
                          feedback !== undefined &&
                          feedback === false ? (
                            <ThumbsDownIconFill
                              color={"#e4022b"}
                              fill={"#e4022b"}
                            />
                          ) : (
                            <ThumbsDownIcon />
                          )}
                        </button>
                      </span>
                      <span className="mr-0.5">
                        <button
                          className="btn btn-icon btn-md btn-pill btn-transparent"
                          onClick={() => handleCopyClick(message)}
                        >
                          <CopyIcon />
                        </button>
                      </span>
                    </div>
                    {/* <ul className="d-flex flex-column mt-2 action-icons justify-center  transition-opacity duration-300 flex flex-row  group-hover:flex"> */}
                    {/* <li className="p-1 pt-0 pr-2 pb-1">
                      <button
                        className="btn btn-icon btn-md btn-pill btn-transparent"
                        onClick={() => handleVote("like", response_id)}
                      >
                        <ThumbsUpIcon />
                      </button>
                    </li>
                    <li className="p-1 pt-0 pr-2 pb-1">
                      <button className="btn btn-icon btn-md btn-pill btn-transparent">
                        <ThumbsDownIcon />
                      </button>
                    </li>
                    <li className="p-1 pt-0 pr-2 pb-1">
                      <button className="btn btn-icon btn-md btn-pill btn-transparent">
                        <CopyIcon />
                      </button>
                    </li> */}
                    {/* <li className="p-1 pt-0 pr-2 pb-1">
                      <button className="btn btn-icon btn-md btn-pill btn-transparent">
                        <DownloadIcon />
                      </button>
                    </li> */}
                    {/* </ul> */}
                  </div>
                </div>
              </span>
            )
          )}
      </div>
    </ScrollToBottom>
  );
});

export default MessageList;
