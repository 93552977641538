import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import kfcLogo from "../../assets/img/KFC_logo.png";
import pizzaLogo from "../../assets/img/pizzaHut.png";
import ai from "../../assets/img/ai.png";
import Cookies from "js-cookie";

const logoDimensions = {
  kfc: { width: "auto", height: "6em" },
  pizzahut: { width: "auto", height: "7em" },
};
const WelcomeScreen = ({ myParam }) => {
  const userAccess = useSelector((state) => state.userAccess);
  const { brand } = userAccess;
  const [logo, setLogo] = useState(brand === "kfc" ? kfcLogo : pizzaLogo);
  const requestBased = Cookies.get("requestBased");


  const [width, setWidth] = useState("auto");
  const [height, setHeight] = useState("7em");

  useEffect(() => {
    if (logo) {
      console.log(logo);
      if (brand) {
        const { width, height } = logoDimensions[brand];
        setWidth(width);
        setHeight(height);
      }
    }
  }, [logo, brand]);

  return (
    <div className="container mt-16 w-10/12	mx-auto">
      <div className="flex justify-center">
        <img
          src={requestBased ==="socket" ? ai : logo}
          style={{ width: width, height: height }}
          alt="KFC"
        />{" "}
      </div>
      <div className="text-center mb-12">
        <h1 className="mt-4 mb-2 wel-msg text-primary-black text-3xl font-semibold font-poppins">
        Welcome to Crave AI
        </h1>
        <p className="content text-sm font-poppins">
          Your AI-powered knowledge Bot.
        </p>
      </div>
    </div>
  );
};

const Content = ({ text }) => {
  return (
    <div className="row g-3 justify-content-center">
      <div className="col-sm-6 col-md-5 col-lg-12">
        <div className="card h-100 border-0">
          <div className="card-body shadow p-4">
            <div className="tyn-text-block">{text}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeScreen;
