export function applyTheme(theme) {
  const root = document.documentElement;
  Object.keys(theme).forEach((cssVar) => {
    root.style.setProperty(cssVar, theme[cssVar]);
  });
}

export function createTheme({
  primaryBlack,
  primaryWhite,
  primaryRed,
  primaryGrey,
}) {
  return {
    "--theme-primaryBlack": primaryBlack,
    "--theme-primaryWhite": primaryWhite,
    "--theme-primaryRed": primaryRed,
    "--theme-primaryGrey": primaryGrey,
  };
}
