// messagesSlice.js
import { createSlice } from "@reduxjs/toolkit";

export const socketSlice = createSlice({
  name: "sockets",
  initialState: [],
  reducers: {
    addQuestion: (state, action) => {
      state.push({
        responseId: action.payload.responseId,
        prompt: action.payload.prompt,
        responses: [],
        conversation_id: action.payload.conversation_id,
      });
    },
    addResponse: (state, action) => {
      const { responseId, answer, sender, response_id, terminate } =
        action.payload;
      const question = state.find(
        (question) => question.responseId === responseId
      );
      if (question) {
        question.responses.push({
          answer,
          sender,
          responseId,
          response_id,
          terminate,
        });
      }
    },
  },
});

export const { addQuestion, addResponse } = socketSlice.actions;
