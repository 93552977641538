import { createTheme } from "./utils";

const kfcTheme = createTheme({
  primaryBlack: "#2a2b2f",
  primaryWhite: "#fff",
  primaryRed: "#e4022b",
  primaryGrey: "#bdbec3",
});

export default kfcTheme;
