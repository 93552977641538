import React, { useEffect, useState } from "react";
import { UpCarpet } from "../../icons";
import kfcBot from "../../assets/img/kfcbot.png";
import pizzaBot from "../../assets/img/pizzabot1.png";

import { useSelector } from "react-redux";

import { getCharacters, userName } from "../../utils/utils";
import ScrollToBottom from "react-scroll-to-bottom";

// import Swal from "sweetalert2";
// import withReactContent from "sweetalert2-react-content";

import ReactMarkdown from "react-markdown";
import Cookies from "js-cookie";
import { useLocation } from "react-router-dom";

// const MySwal = withReactContent(Swal);

const botDimensions = {
  kfc: { width: 32, height: 32 },
  pizzahut: { width: 32, height: 32 },
};

const name = userName();

const SocketPlayGroundMessages = React.memo(({ messages }) => {
  const requestBased = Cookies.get("requestBased");
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const myParam = params.get("id");

  const userAccess = useSelector((state) => state.userAccess);

  const [bot] = useState(userAccess.brand === "kfc" ? kfcBot : pizzaBot);

  const [width, setWidth] = useState(32);
  const [height, setHeight] = useState(32);

  useEffect(() => {
    if (bot) {
      const brand = userAccess.brand;
      const { width, height } = botDimensions[brand];
      setWidth(width);
      setHeight(height);
    }
  }, [bot, userAccess.brand]);

  return (
    <ScrollToBottom className="flex relative left flex-col">
      <div className="border-0 mx-6 mr-10  mt-3 justify-center ">
        {messages.map((question) => (
          <div key={question.id}>
            <div className="tyn-qa-item flex gap-4 w-full">
              <div className="tyn-qa-avatar align-middle ">
                <div className="tyn-media tyn-size-md">
                  <div
                    className={`relative inline-flex items-center justify-center w-8 h-8 overflow-hidden  rounded-full ${
                      myParam ? "bg-socket-light" : "bg-primary-red"
                    } object-contain 	`}
                  >
                    <span className="font-medium text-primary-white">
                      {name}
                    </span>
                  </div>
                </div>
              </div>
              <div
                className={`self-center  ${
                  myParam ? "bg-socket-dark" : "bg-primary-black"
                } text-primary-white font-normal text-sm w-full rounded-lg flex justify-between p-[10px]`}
              >
                <p className="text-base font-medium">{question.prompt}</p>
                <span className="float-right self-center ">
                  <UpCarpet width="28" height="28" />
                </span>
              </div>
            </div>

            <div className="flex ">
              <div
                className="flex-1"
                style={{ margin: "25px 0", marginLeft: "35px" }}
              >
                <div className="">
                  {question.responses.map(
                    ({ answer, sender, terminate }, index) => (
                      <div>
                        {console.log(question.responses, "Resp")}
                        <div
                          style={{
                            marginRight: index % 2 === 0 ? "30%" : "0",
                            marginLeft: index % 2 === 0 ? "0" : "30%",
                          }}
                          key={index}
                          className={`flex mb-4 cursor-pointer ${
                            index % 2 === 0 ? "justify-start" : "justify-end"
                          }`}
                        >
                          {index % 2 === 0 ? (
                            <>
                              <div className="tyn-qa-avatar align-middle mx-2">
                                <div className="tyn-media tyn-size-md">
                                  <div
                                    className={`relative inline-flex items-center justify-center w-8 h-8 overflow-hidden  rounded-full ${
                                      myParam
                                        ? "bg-socket-dark"
                                        : "bg-primary-black"
                                    } object-contain 	`}
                                  >
                                    <span className="font-medium text-primary-white ">
                                      {getCharacters(sender)}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="max-w-96 bg-primary white shadow-md rounded-lg p-3 gap-3">
                                <p className="text-gray-700 text-primary-black">
                                  <ReactMarkdown>{answer}</ReactMarkdown>
                                </p>
                              </div>
                            </>
                          ) : (
                            <>
                              <div
                                className={` max-w-96 ${
                                  myParam
                                    ? "bg-socket-dark"
                                    : "bg-primary-black"
                                } text-primary-white shadow-lg rounded-lg p-3 gap-3`}
                              >
                                <ReactMarkdown>{answer}</ReactMarkdown>
                              </div>
                              <div className="tyn-qa-avatar align-middle mx-2">
                                <div className="tyn-media tyn-size-md">
                                  <div
                                    className={`relative inline-flex items-center justify-center w-8 h-8 overflow-hidden  rounded-full ${
                                      myParam
                                        ? "bg-socket-dark"
                                        : "bg-primary-red"
                                    } object-contain 	`}
                                  >
                                    <span className="font-medium text-primary-white ">
                                      {getCharacters(sender)}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </ScrollToBottom>
  );
});

export default SocketPlayGroundMessages;
