import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Select, Drawer, Button, Checkbox, Form, Tag } from "antd";
import { FilterOutlined, FilterFilled, RedoOutlined } from "@ant-design/icons";
import {
  useGetFilterDataQuery,
  setFilters,
  resetFilters,
} from "../../services/filterService";

const { Option } = Select;

const TwoLevelFilterDropdown = ({ spaceId }) => {
  const dispatch = useDispatch();
  const { data: filters, error, isLoading } = useGetFilterDataQuery(spaceId);
  const selectedFilters = useSelector((state) => state.filters.selectedFilters);

  const [selectedFilterKey, setSelectedFilterKey] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [drawerVisible, setDrawerVisible] = useState(false);

  const handleFilterKeySelect = (value) => {
    setSelectedFilterKey(value);
  };

  const handleCheckboxChange = (filterKey, option) => {
    setSelectedOptions((prevSelectedOptions) => {
      const isSelected = prevSelectedOptions[filterKey]?.includes(option);
      const updatedOptions = isSelected
        ? prevSelectedOptions[filterKey].filter((item) => item !== option)
        : [...(prevSelectedOptions[filterKey] || []), option];

      const newSelectedOptions = {
        ...prevSelectedOptions,
        [filterKey]: updatedOptions,
      };

      // Update Redux state
      dispatch(setFilters(newSelectedOptions));

      return newSelectedOptions;
    });
  };

  const removeFilter = (filterKey, option) => {
    setSelectedOptions((prevSelectedOptions) => {
      const updatedOptions = prevSelectedOptions[filterKey].filter(
        (item) => item !== option
      );

      const newSelectedOptions = {
        ...prevSelectedOptions,
        [filterKey]: updatedOptions,
      };

      if (updatedOptions.length === 0) {
        delete newSelectedOptions[filterKey];
      }

      // Update Redux state
      dispatch(setFilters(newSelectedOptions));

      return newSelectedOptions;
    });
  };

  const resetAllFilters = () => {
    setSelectedOptions({});
    setSelectedFilterKey(null);
    dispatch(resetFilters());
  };

  const toggleDrawer = () => {
    setDrawerVisible(!drawerVisible);
  };

  if (isLoading || error) return null;

  return (
    <div>
      <Button
        type="primary"
        icon={
          Object.keys(selectedOptions).length ? (
            <FilterFilled style={{ color: "red", fontSize: "24px" }} />
          ) : (
            <FilterOutlined style={{ fontSize: "24px" }} />
          )
        }
        onClick={toggleDrawer}
        size="large"
        style={{
          position: "fixed",
          top: "50%",
          right: 0,
          transform: "translateY(-50%)",
          zIndex: 100,
          background: "#242745",
          borderRadius: "10px 0px 0px 10px",
          padding: "25px",
          display: filters === null ? "none" : "",
        }}
      />

      <Drawer
        title={<h3 class="text-primary text-xl font-semibold">Filters</h3>}
        placement="right"
        onClose={toggleDrawer}
        visible={drawerVisible}
        getContainer={false}
        style={{ position: "fixed", width: "370px" }}
        footer={
          <Button
            onClick={resetAllFilters}
            type="default"
            block
            size="large"
            className="font-medium text-md"
          >
            <RedoOutlined />
            Clear All Filters
          </Button>
        }
        footerStyle={{ padding: "10px 16px", background: "#f6f6f6" }}
      >
        <Form layout="vertical">
          <div
            style={{
              marginBottom: Object.keys(selectedOptions).length
                ? "20px"
                : "0px",
            }}
          >
            {Object.keys(selectedOptions).map((filterKey) =>
              selectedOptions[filterKey].map((option) => (
                <Tag
                  key={`${filterKey}-${option}`}
                  closable
                  onClose={() => removeFilter(filterKey, option)}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "5px",
                    padding: "5px 10px",
                    fontSize: "15px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "fit-content",
                  }}
                  className="font-medium text-md"
                >
                  <span
                    style={{
                      flexGrow: 1,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {filterKey}: {option}
                  </span>
                </Tag>
              ))
            )}
          </div>

          <Form.Item label={<span className="text-md">Select Filter</span>}>
            <Select
              placeholder="Select Filter"
              onChange={handleFilterKeySelect}
              style={{ width: "100%" }}
              className="text-md font-medium"
              size="large"
            >
              {filters &&
                Object.keys(filters).map((filterKey) => (
                  <Option key={filterKey} value={filterKey}>
                    {filterKey}
                  </Option>
                ))}
            </Select>
          </Form.Item>

          {selectedFilterKey && (
            <Form.Item
              label={
                <span className="text-md font-medium">
                  Select {selectedFilterKey} Options
                </span>
              }
            >
              {filters[selectedFilterKey].map((option, index) => (
                <Checkbox
                  key={index}
                  checked={selectedOptions[selectedFilterKey]?.includes(option)}
                  onChange={() =>
                    handleCheckboxChange(selectedFilterKey, option)
                  }
                  style={{ marginBottom: "10px" }}
                  className="text-md font-medium"
                  disabled={selectedOptions[selectedFilterKey]?.length}
                >
                  {option}
                </Checkbox>
              ))}
            </Form.Item>
          )}
        </Form>
      </Drawer>
    </div>
  );
};

export default TwoLevelFilterDropdown;
