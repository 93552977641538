import React, { useEffect, useRef, useState } from "react";
import MessageList from "../MessageList/MessageList";
import Messager from "../Messager/Messager";
import { useSelector } from "react-redux";
import {
  conversationApi,
  useLazyGetHistoryQuery,
  useLazyGetSocketHistoryQuery,
  usePostConversationMutation,
} from "../../services/conversationService";
import { RedLoader } from "../Loaders/Loaders";
import Error from "../Error/Error";

import SingleMsg from "../SingleMsg/SingleMsg";
import WelcomeScreen from "../WelcomeComponenet/WelcomeScreen";

import { useDispatch } from "react-redux";
import { addQuestion, addResponse } from "../../services/socketSlice";
import SocketMessages from "../SocketMessages/SocketMessages";

import useWebSocket from "react-use-websocket";
import { socketUrl } from "../../constants/constants";
import Cookies from "js-cookie";
import { generateMongoLikeId, userEmail } from "../../utils/utils";
import SocketPlayGroundMessages from "../SocketPlayGroundMsgs/SocketPlayGroundMessages";
import { useAppDispatch } from "../../app/store";
import { current } from "@reduxjs/toolkit";

function getRandomId() {
  const randomString = Math.random().toString(36).substring(2, 15); // Avoid the first two characters (".")
  const prefix = "random-"; // Optional prefix for clarity
  return prefix + randomString;
}

const isJSON = (str) => {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
};

const defaultConvId = generateMongoLikeId();
// const ws = new WebSocket(socketUrl);

const MessageWrapper = ({ menuOpen, showWelcome, setShowWelcome, myParam }) => {
  const requestBased = Cookies.get("requestBased");
  const activeConfig = Cookies.get("activeConfig");

  const selectedConversation = useSelector(
    (state) => state.selectedConversation
  );

  const prevSelectedConversation = useSelector(
    (state) => state.prevSelectedConversation
  );

  let socketMessages = useSelector((state) => state.messages);

  const [connectionStatus, setConnectionStatus] = useState("Connecting");

  const [isUserChecked, setIsUserChecked] = useState(true);

  const [text, setText] = useState();
  const [prompt, setPromt] = useState();
  const [msgData, setMsgData] = useState();
  const [formBody, setFormBody] = useState();
  const [loadedAgain, setLoadedAgain] = useState(false);

  const [socketConversationStarted, setSocketConversationStarted] =
    useState(false);

  const [msgCounter, setMsgCounter] = useState(0);

  const msgCounterRef = useRef(msgCounter);

  const [questionId, setQuestionId] = useState();

  const questionIdRef = useRef(questionId);

  const selectedFilters = useSelector((state) => state.filters.selectedFilters);

  const getFilters = () => {
    let encodedFilters = "";
    if (selectedFilters && Object.keys(selectedFilters).length > 0) {
      const queryParams = new URLSearchParams(selectedFilters).toString();
      encodedFilters += `&${queryParams}`;
    }
    return encodedFilters;
  };

  const [webSocketUrl, setWebSocketUrl] = useState(
    `${socketUrl}?wid=${
      myParam ? myParam : activeConfig
    }${getFilters()}&user_id=${userEmail()}&conversation_id=${
      selectedConversation && selectedConversation !== "nofetch"
        ? selectedConversation
        : generateMongoLikeId()
    }`
  );

  const { sendMessage, lastMessage, readyState, getWebSocket } = useWebSocket(
    webSocketUrl,
    {
      onOpen: () => {
        console.log("WebSocket connection established.");
        setConnectionStatus("");
      },
      onError: (error) => {
        console.error("WebSocket error:", error);
        setConnectionStatus("Error");
        // Handle the error appropriately (e.g., display error message to user)
      },
      shouldReconnect: () => true,
      reconnectAttempts: 20,
      reconnectInterval: 3000,
    }
  );

  const messagesEndRef = useRef(null);

  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();

  socketMessages = socketMessages.filter(
    (message) => message.conversation_id === selectedConversation
  );

  const [getHistory, { data, error, isFetching, isFulfilled }] =
    useLazyGetHistoryQuery();

  const [
    getSocketHistory,
    {
      data: socketData,
      error: socketDataError,
      isFetching: socketDataFetching,
    },
  ] = useLazyGetSocketHistoryQuery();

  const [createMsg, createMsgInfo] = usePostConversationMutation();

  const sendSocketMessage = (message) => {
    sendMessage(message.prompt);
  };

  useEffect(() => {
    setWebSocketUrl(
      `${socketUrl}?wid=${
        myParam ? myParam : activeConfig
      }${getFilters()}&user_id=${userEmail()}&conversation_id=${
        selectedConversation && selectedConversation !== "nofetch"
          ? selectedConversation
          : generateMongoLikeId()
      }`
    );
    if (
      prevSelectedConversation &&
      prevSelectedConversation !== "nofetch" &&
      selectedConversation &&
      selectedConversation !== "nofetch"
    ) {
      setSocketConversationStarted(false);
      setMsgCounter(0);
      setPromt();
    }
    if (
      prevSelectedConversation &&
      prevSelectedConversation !== "nofetch" &&
      selectedConversation &&
      selectedConversation === "nofetch"
    ) {
      setSocketConversationStarted(false);
      setMsgCounter(0);
      setPromt();
    }
  }, [selectedConversation, selectedFilters]);

  useEffect(() => {
    requestBased === "http" && setPromt();
    if (selectedConversation && selectedConversation !== "nofetch") {
      requestBased === "socket"
        ? getSocketHistory(selectedConversation)
        : getHistory(selectedConversation);
    }
    if (selectedConversation) setShowWelcome(false);
    if (selectedConversation === "nofetch") setMsgData([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getHistory, getSocketHistory, selectedConversation, setShowWelcome]);

  useEffect(() => {
    if (lastMessage) {
      const { data } = lastMessage;

      const parsedData = JSON.parse(data);
      console.log(parsedData, "parsedData");

      if (parsedData.data) {
        const {
          message,
          sender,
          terminate,
          conversation_id,
          prompt,
          app_id,
          user_id,
          response_id,
        } = parsedData.data;

        if (!selectedConversation || selectedConversation === "nofetch") {
          const patchCollection = appDispatch(
            conversationApi.util.updateQueryData(
              "getConversations",
              myParam ? myParam : "",
              (draftPosts) => {
                const conversationExists = draftPosts.some((conversation) => {
                  return conversation.conversation_id === conversation_id;
                });

                if (!conversationExists) {
                  // If conversation does not exist, add new conversation data
                  const newConversation = {
                    conversation_id: conversation_id,
                    conversation_name: prompt,
                    app_uuid: app_id,
                    yum_id: user_id,
                    pin: false,
                    deleted: false,
                  };

                  // Push new conversation to draftPosts
                  draftPosts.unshift(newConversation);
                  setMsgCounter(1);
                  // Log the updated draftPosts after pushing new conversation
                  console.log(
                    "Updated draftPosts after adding new conversation:",
                    draftPosts
                  );
                } else {
                  console.log(
                    "Conversation with conversation_id already exists:",
                    "6639c2523bab88fbe4189"
                  );
                }
              }
            )
          );
        }
        if (selectedConversation && selectedConversation !== "nofetch") {
          const patchCollection2 = appDispatch(
            conversationApi.util.updateQueryData(
              "getSocketHistory",
              conversation_id,
              (draftPosts) => {
                if (draftPosts) {
                  const copy = current(draftPosts);

                  if (msgCounterRef.current === 0) {
                    console.log("*****************IST MSG*********");
                    const newData = {
                      prompt: prompt,
                      conversation_name: prompt,
                      conversation_id: conversation_id,
                      app_id: app_id,
                      yum_id: user_id,
                      visible: terminate,
                      messages: [
                        {
                          response_id: response_id,
                          message: message.content,
                          terminate: terminate,
                          sender: sender,
                        },
                      ],
                    };
                    draftPosts.push(newData);
                    setMsgCounter(1);
                  } else {
                    console.log("*****************2nd MSG*********");

                    const newResp = {
                      response_id: response_id,
                      message: message.content,
                      terminate: terminate,
                      sender: sender,
                    };

                    const updated = copy.map((item, index) => {
                      const newObj = { ...item };
                      const messages = [...newObj.messages];

                      const isLast = index === copy.length - 1;

                      if (isLast) {
                        messages.push(newResp);
                        newObj["messages"] = messages;
                        newObj["visible"] = terminate;
                      }
                      return newObj;
                    });

                    Object.assign(draftPosts, updated);
                  }
                }
              }
            )
          );
        }

        const latestQuestionId = questionIdRef.current;

        if (sender && sender !== "userproxy") {
          dispatch(
            addResponse({
              responseId: latestQuestionId,
              answer: message.content,
              sender: sender,
              terminate: terminate,
            })
          );
        }
        if (terminate === true) {
          setSocketConversationStarted(false);
          setMsgCounter(0);
          setPromt();
        }
      }
    }
  }, [appDispatch, dispatch, lastMessage, selectedConversation]);

  useEffect(() => {
    if (socketData) {
      setMsgData(socketData);
    }
    if (
      socketData &&
      socketData.length > 0 &&
      requestBased === "socket" &&
      selectedConversation &&
      selectedConversation !== "nofetch"
    ) {
      socketData.forEach((item) => {
        const newQuesId = getRandomId();
        dispatch(
          addQuestion({
            responseId: newQuesId,
            prompt: item.prompt,
            conversation_id: item.conversation_id,
          })
        );
        item.messages.forEach((response) => {
          if (response.sender !== "userproxy")
            dispatch(
              addResponse({
                responseId: newQuesId,
                answer: response.message,
                sender: response.sender,
                response_id: response.response_id,
                terminate: response.terminate,
              })
            );
        });
      });
    }
  }, [socketData, dispatch, requestBased, selectedConversation]);

  useEffect(() => {
    if (data) {
      setMsgData(data);
    }
  }, [data, dispatch, loadedAgain, requestBased]);

  useEffect(() => {
    if (createMsgInfo.isSuccess) {
      setPromt("");
    }
  }, [createMsgInfo]);

  useEffect(() => {
    questionIdRef.current = questionId;
  }, [questionId]);

  useEffect(() => {
    msgCounterRef.current = msgCounter;
  }, [msgCounter]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (text) {
      setPromt(text);
      setShowWelcome(false);
      requestBased === "socket" && setSocketConversationStarted(true);
      const body = {
        text: text,
      };
      if (selectedConversation && selectedConversation !== "nofetch")
        body["conversation_id"] = selectedConversation;
      setFormBody(body);
      !myParam && requestBased !== "socket" && createMsg(body);

      if (myParam || requestBased === "socket") {
        const newQuesId = getRandomId();
        setQuestionId(newQuesId);
        sendSocketMessage({
          prompt: text,
          sender: "user",
          response_id: newQuesId,
        });
        dispatch(
          addQuestion({
            responseId: newQuesId,
            prompt: text,
            conversation_id: selectedConversation,
          })
        );
      }
      setText("");
    }
  };

  const handleRegenerate = () => {
    createMsgInfo.reset();
    createMsg(formBody);
  };

  return (
    <main
      className={`w-full lg:w-3/4 fixed left-0 bottom-0  z-1 flex flex-col overflow-auto  transform ease-in-out duration-300 custom-height lg:ml-[24.5%]
      ${
        window.mapp
          ? "top-0"
          : requestBased === "socket"
          ? "top-[91px]"
          : "top-[75px]"
      } `}
    >
      <nav className="side-menu-nav flex-grow flex flex-col overflow-auto scroll-smooth ">
        {myParam && connectionStatus === "Error" && (
          <div className="mt-5 flex justify-center">
            <div className="bg-red-500 text-white font-medium px-2 py-1 rounded">
              <small>Not able to set up connection</small>
            </div>
          </div>
        )}

        {!myParam && requestBased !== "socket" ? (
          <>
            {isFetching ? (
              <div className="mx-auto mt-4">
                <RedLoader />
              </div>
            ) : error ? (
              <Error msg={error.error ? error.error : error.data.detail} />
            ) : (
              msgData &&
              msgData.length > 0 &&
              !showWelcome && <MessageList data={msgData} />
            )}

            {prompt && (
              <SingleMsg
                prompt={prompt}
                error={createMsgInfo.error}
                isLoading={createMsgInfo.isLoading}
                handleRegenerate={handleRegenerate}
              />
            )}
            <div ref={messagesEndRef} />
            {showWelcome && <WelcomeScreen myParam={myParam} />}
          </>
        ) : (
          requestBased === "socket" && (
            <>
              {socketDataFetching ? (
                <div className="mx-auto mt-4">
                  <RedLoader />
                </div>
              ) : socketDataError ? (
                <Error
                  msg={
                    socketDataError.error
                      ? socketDataError.error
                      : socketDataError.data.detail
                  }
                />
              ) : (
                msgData &&
                msgData.length > 0 &&
                !showWelcome &&
                selectedConversation !== "nofetch" && (
                  <SocketMessages
                    socketData={msgData}
                    socketConversationStarted={socketConversationStarted}
                  />
                )
              )}

              {prompt && (
                <SingleMsg
                  prompt={prompt}
                  error={createMsgInfo.error}
                  isLoading={socketConversationStarted}
                  handleRegenerate={handleRegenerate}
                  requestBased={requestBased}
                  socketData={msgData}
                />
              )}
              <div ref={messagesEndRef} />
              {showWelcome && <WelcomeScreen myParam={myParam} />}
            </>
          )
        )}
      </nav>
      <footer
        className="side-menu-footer flex-shrink-0 bg-skyblue  text-white py-5  border-primary-grey mx-auto"
        style={{ width: menuOpen ? "calc(100% - 25vw)" : "85%" }}
      >
        {/* <SidebarBtns /> */}
        <form action="" onSubmit={(e) => handleSubmit(e)}>
          <Messager
            text={text}
            setText={setText}
            handleSubmit={handleSubmit}
            myParam={myParam}
            socketConversationStarted={socketConversationStarted}
          />
        </form>
      </footer>
    </main>
  );
};

export default MessageWrapper;
