import { createSlice } from "@reduxjs/toolkit";

export const selectedConversationSlice = createSlice({
  name: "selectedConversation",
  initialState: null, // You can set the initial state as needed
  reducers: {
    setSelectedConversation: (state, action) => {
      return action.payload;
    },
  },
});

export const { setSelectedConversation } = selectedConversationSlice.actions;

export const prevSelectedConversationSlice = createSlice({
  name: "prevSelectedConversationSlice",
  initialState: null, // You can set the initial state as needed
  reducers: {
    setPrevSelectedConversation: (state, action) => {
      return action.payload;
    },
  },
});

export const { setPrevSelectedConversation } =
  prevSelectedConversationSlice.actions;

// import { createSlice } from "@reduxjs/toolkit";

// export const selectedConversationSlice = createSlice({
//   name: "selectedConversation",
//   initialState: {
//     selectedConversation: null,
//     previouSelectedConv: null,
//   },
//   reducers: {
//     setSelectedConversation: (state, action) => {
//       console.log(state, action, "statepay");
//       if (state.selectedConversation) {
//         const prevValue = state.selectedConversation;
//         state.previouSelectedConv = prevValue;
//       }

//       return action.payload;
//     },
//   },
// });

// export const { setSelectedConversation } = selectedConversationSlice.actions;
