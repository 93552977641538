// import React, { useEffect, useState } from "react";
// import { useSelector } from "react-redux";

// import kfcLogo from "../../assets/img/KFC_logo.png";
// import pizzaLogo from "../../assets/img/pizzaHut.png";
// import { clearCookies } from "../../utils/utils";
// import Navbar from "../../Components/Navbar/Navbar";
// import { Link } from "react-router-dom";
// import yumLogo from "../../assets/img/yum_logo.png";

// const logoDimensions = {
//   kfc: { width: "auto", height: "6em" },
//   pizzahut: { width: "auto", height: "7em" },
// };

// const Logout = () => {
//   const userAccess = useSelector((state) => state.userAccess);
//   const { brand } = userAccess;
//   const [logo, setLogo] = useState(brand === "kfc" ? kfcLogo : pizzaLogo);

//   const [width, setWidth] = useState("auto");
//   const [height, setHeight] = useState("7em");

//   useEffect(() => {
//     clearCookies();
//   }, []);

//   useEffect(() => {
//     if (logo) {
//       console.log(logo);
//       if (brand) {
//         const { width, height } = logoDimensions[brand];
//         setWidth(width);
//         setHeight(height);
//       }
//     }
//   }, [logo, brand]);
//   return (
//     <div className="container mt-52 w-10/12	mx-auto">
//       <div className="flex justify-center">
//         <img src={yumLogo} style={{ width: width, height: height }} alt="KFC" />
//       </div>
//       <div className="text-center mb-12">
//         <h1 className="mt-4 mb-2 wel-msg text-primary-black text-3xl font-semibold font-poppins">
//           You have been successfully logged out.
//         </h1>
//         <button
//           type="button"
//           className="focus:outline-none text-white bg-primary-red hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-primary-red dark:focus:ring-red-900 mt-5"
//         >
//           Login Again
//         </button>
//       </div>
//     </div>
//   );
// };

// // const LogoutNav = () => {
// //   return (
// //     <nav className="flex items-center justify-between border-b inset-0 right-0 bottom-auto left-0 fixed">
// //       <div className={`flex items-center`}>
// //         <Link to={"/"} className=" p-[1rem] ">
// //           <div className="inline-flex items-center gap-2">
// //             {/* Add your logo/image here */}
// //             <img src={yumLogo} className="object-contain" alt="KFC" />
// //           </div>
// //         </Link>
// //       </div>
// //     </nav>
// //   );
// // };

// export default Logout;
import yumLogo from "../../assets/img/yum_logo.png";

import React, { useEffect } from "react";
import styles from "./platform.module.css";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

import { loginPath } from "../../constants/constants";
import { clearCookies } from "../../utils/utils";

const Platform = () => {
  useEffect(() => {
    clearCookies();
  }, []);
  const particlesInit = async (main) => {
    console.log(main);
    await loadFull(main);
  };

  const particlesLoaded = (container) => {
    console.log(container);
  };

  return (
    <div className={styles.platform}>
      <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={{
          particles: {
            number: {
              value: 60,
              density: {
                enable: true,
                value_area: 500,
              },
            },
            color: {
              value: "#b1c900",
            },
            shape: {
              type: "circle",
              stroke: {
                width: 0,
                color: "#000000",
              },
              polygon: {
                nb_sides: 5,
              },
              image: {
                width: 100,
                height: 100,
              },
            },
            opacity: {
              value: 0.5,
              random: false,
              anim: {
                enable: false,
                speed: 1,
                opacity_min: 0.1,
                sync: false,
              },
            },
            size: {
              value: 5,
              random: true,
              anim: {
                enable: false,
                speed: 40,
                size_min: 0.1,
                sync: false,
              },
            },
            line_linked: {
              enable: true,
              distance: 150,
              color: "#ffffff",
              opacity: 0.4,
              width: 1,
            },
            move: {
              enable: true,
              speed: 4,
              direction: "none",
              random: false,
              straight: false,
              out_mode: "out",
              attract: {
                enable: false,
                rotateX: 600,
                rotateY: 1200,
              },
            },
          },
          interactivity: {
            detect_on: "canvas",
            events: {
              onhover: {
                enable: true,
                mode: "repulse",
              },
              onclick: {
                enable: true,
                mode: "push",
              },
              resize: true,
            },
            modes: {
              grab: {
                distance: 400,
                line_linked: {
                  opacity: 1,
                },
              },
              bubble: {
                distance: 400,
                size: 40,
                duration: 2,
                opacity: 8,
                speed: 3,
              },
              repulse: {
                distance: 100,
              },
              push: {
                particles_nb: 4,
              },
              remove: {
                particles_nb: 2,
              },
            },
          },
          retina_detect: true,
          config_demo: {
            hide_card: false,
            background_color: "#b61924",
            background_image: "",
            background_position: "50% 50%",
            background_repeat: "no-repeat",
            background_size: "cover",
          },
        }}
      ></Particles>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12" style={{ margin: "0 15px" }}>
            <div className={`${styles.pfSelection} `}>
              <div className={`${styles.logo_wrapper} gap-2`}>
                <img
                  src={yumLogo}
                  alt="logo"
                  style={{ width: "30%", height: "auto" }}
                />
              </div>
              <div className={`text-center ${styles.pfHeading}`}>
                <p className="mt-4 mb-2 wel-msg text-primary-black text-3xl font-semibold font-poppins">
                  You have been successfully logged out.
                </p>
                <a href={`${loginPath}`}>
                  <button
                    type="button"
                    className="focus:outline-none text-white bg-primary-red hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-red-600 dark:hover:bg-primary-red dark:focus:ring-red-900 mt-5 rounded"
                    style={{ fontWeight: 700, background: "#312c2d" }}
                  >
                    Login Again
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Platform;
