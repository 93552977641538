import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import kfcLogo from "../../assets/img/kfc.svg";
import pizzaLogo from "../../assets/img/pizza.png";
import aiLogo from "../../assets/img/ai.png";

import { useSelector } from "react-redux";
import { userEmail, userName } from "../../utils/utils";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";

const name = userName();
const logoDimensions = {
  kfc: { width: 119, height: 36 },
  pizzahut: { width: 180, height: 36 },
  aiLogo: { width: 48, height: "auto" },
};

function doesEmailExist(email, arrayOfObjects) {
  // Iterate over each object in the array
  if (arrayOfObjects && arrayOfObjects.length > 0) {
    for (let i = 0; i < arrayOfObjects.length; i++) {
      // Check if the current object has an 'email' property and if it matches the provided email
      if (arrayOfObjects[i].user_email === email) {
        return true; // Email exists in the array
      }
    }
  }
  return false; // Email does not exist in the array
}

function setCookie(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + value + expires + "; path=/";
}

const Navbar = ({ setMenuOpen, menuOpen, workforceData, isFetching }) => {
  const location = useLocation();
  const activeConfigName = Cookies.get("activeConfigName");

  const requestBased = Cookies.get("requestBased");

  const params = new URLSearchParams(location.search);
  const myParam = params.get("id");
  const userAccess = useSelector((state) => state.userAccess);
  const { brand } = userAccess;
  const [logo, setLogo] = useState(
    requestBased === "socket" ? aiLogo : brand === "kfc" ? kfcLogo : pizzaLogo
  );
  const [width, setWidth] = useState(119);
  const [height, setHeight] = useState(36);
  const [userWorkforces, setUserWorkforces] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);

  const handleMouseEnter = () => {
    setIsDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    if (workforceData && workforceData.length > 0) {
      const option = [];
      workforceData.forEach((item) => {
        if (doesEmailExist(userEmail(), item.users)) {
          option.push(item);
        }
      });
      setUserWorkforces(option);
    }
  }, [workforceData]);

  useEffect(() => {
    const updateMedia = () => {
      setIsMobile(window.innerWidth <= 1024);
    };
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  useEffect(() => {
    if (logo) {
      if (brand && requestBased !== "socket") {
        const { width, height } = logoDimensions[brand];
        setWidth(width);
        setHeight(height);
      } else {
        const { width, height } = logoDimensions.aiLogo;

        setWidth(width);
        setHeight(height);
      }
    }
  }, [logo, brand, myParam]);

  const handleWorkforce = (activeConfig, requestBased, name) => {
    if (activeConfig === "default") {
      let stdBotConfig = Cookies.get("stdBotConfig");
      Cookies.set("requestBased", requestBased, { expires: 365 });
      Cookies.set("activeConfig", stdBotConfig, { expires: 365 });
      Cookies.set("activeConfigName", "Standard Bot", { expires: 365 });
    } else {
      Cookies.set("activeConfig", activeConfig, { expires: 365 });
      Cookies.set("requestBased", requestBased, { expires: 365 });
      Cookies.set("activeConfigName", name, { expires: 365 });
    }
    window.location.reload();
  };

  return (
    <nav
      className="flex items-center justify-between border-b inset-0 right-0 bottom-auto left-0 relative z-10"
      style={{ background: "white" }}
    >
      <div className="flex items-center lg:hidden ">
        <button
          onClick={() => setMenuOpen(!menuOpen)}
          className="text-3xl cursor-pointer"
        >
          &#9776;
        </button>
      </div>
      <div
        className={` ${
          isMobile
            ? "flex items-center"
            : "flex items-center border-r border-solid border-1"
        }`}
      >
        <Link to={"/"} className=" p-[1rem] ">
          <div className="inline-flex items-center gap-2">
            {/* Add your logo/image here */}
            <img
              src={logo}
              className="object-contain"
              alt="KFC"
              width={width}
              height={height}
              style={{ width: width, height: height }}
            />

            {requestBased === "socket" && (
              <h1
                style={{
                  fontSize: 25,
                  marginLeft: "-5px",
                  marginRight: 5,
                  marginTop: 10,
                }}
                className="mt-4 mb-2 wel-msg text-primary-black text-3xl  font-poppins"
              >
                CraveAI
              </h1>
            )}
          </div>
        </Link>
      </div>

      <div className="flex items-center p-[1rem]">
        {!isFetching && !myParam ? (
          userWorkforces?.length > 0 ? (
            <button
              id="dropdownDelayButton"
              data-dropdown-toggle="dropdownDelay"
              data-dropdown-delay={500}
              data-dropdown-trigger="hover"
              className="text-primary-black bg-primary-white  focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center "
              type="button"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              {activeConfigName}
              <svg
                className="w-2.5 h-2.5 ms-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 10 6"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="m1 1 4 4 4-4"
                />
              </svg>
              {isDropdownOpen && (
                <div
                  id="dropdownDelay"
                  className="z-10 absolute bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 top-[58px]  right-2.5"
                >
                  <ul
                    className="py-2 text-sm text-gray-700 dark:text-gray-200"
                    aria-labelledby="dropdownDelayButton"
                  >
                    {userWorkforces?.map((workforce, index) => (
                      <li
                        key={index}
                        onClick={() =>
                          handleWorkforce(
                            workforce.workforce_versions[0]
                              .workforce_version_id,
                            "socket",
                            workforce.title
                          )
                        }
                      >
                        <Link
                          to={`#`}
                          className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white text-left"
                        >
                          {workforce.title}
                        </Link>
                      </li>
                    ))}
                    <li
                      onClick={() =>
                        handleWorkforce("default", "http", "Standard Bot")
                      }
                    >
                      <Link
                        to="#"
                        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white text-left"
                      >
                        Standard Bot
                      </Link>
                    </li>
                  </ul>
                </div>
              )}
            </button>
          ) : (
            <div
              className={`relative inline-flex items-center justify-center w-10 h-10 overflow-hidden rounded-full object-contain ${
                brand === "kfc" ? "bg-primary-black" : "bg-primary-red"
              }`}
            >
              <span className="font-medium text-primary-white">{name}</span>
            </div>
          )
        ) : null}
      </div>
    </nav>
  );
};

export default Navbar;
