import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { dfpBaseUrl, env } from "../constants/constants";
import Cookies from "js-cookie";

export const workforceApi = createApi({
  reducerPath: "workforceApi",

  baseQuery: fetchBaseQuery({
    baseUrl: dfpBaseUrl,
    prepareHeaders: (headers) => {
      const accessToken = Cookies.get("jwt_access_token");
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }
      return headers;
    },
    credentials: env === "development" ? "omit" : "include",
  }),
  keepUnusedDataFor: 86400,
  endpoints: (builder) => ({
    getWorkforces: builder.query({
      query: () => `workforces/`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Workforce", id })),
              { type: "Workforce", id: "WorkforcesList" },
            ]
          : [{ type: "Workforce", id: "WorkforcesList" }],
    }),
  }),
});

export const { useGetWorkforcesQuery } = workforceApi;
