import { createTheme } from "./utils";

const pizzaTheme = createTheme({
  // primaryBlack: "#231f20",
  primaryBlack: "#2a2b2f",

  primaryWhite: "#fff",
  primaryRed: "#c8102e",
  primaryGrey: "#bdbec3",
});

export default pizzaTheme;
